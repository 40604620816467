import { UserAuthResponse } from "@/models/auth";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { LocalStorageService } from "./local_storage_service";

export default abstract class HttpClient {
  protected readonly _axios: AxiosInstance;

  public constructor(baseURL: string, timeout?: number) {
    this._axios = axios.create({
      baseURL,
      timeout,
    });
    this._initRequestInterceptor();
    this._initResponseInterceptor();
  }

  private _initRequestInterceptor = () => {
    this._axios.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _initResponseInterceptor = () => {
    this._axios.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    if (config != null) {
      const token = LocalStorageService.instance().getAccessToken();
      if (token && config.headers != null) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  };

  private _handleResponse = (response: AxiosResponse) => {
    const isSuccessfulLogin =
      response.config.url != null &&
      response.config.url === "/users/authenticate" &&
      response.status == 200;

    if (isSuccessfulLogin) {
      try {
        const responseData = response.data as UserAuthResponse;
        if (responseData.objectResponse.token != null) {
          LocalStorageService.instance().setAccessToken(
            responseData.objectResponse.token
          );
        }
      } catch (e) {
        console.error(e);
      }
    }
    return response;
  };

  protected _handleError = (error: any) => Promise.reject(error);
}
