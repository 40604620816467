import {
  PharmacyInfoModel,
  PharmacySearchReq,
} from "@/models/pharmacy/pharmacy-search";
import DataService from "@/services/data_service";
import { FilterMatchMode } from "primevue/api";
import { reactive, ref, toRefs, watch } from "vue";

const searchTerm = ref("");
const pharmacies = ref<PharmacyInfoModel[]>([]);

const filters = reactive({
  zpaContract: false,
});

const keywordFilters = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
});

const loading = ref(false);

export default (): any => {
  // Filters
  // const toggleZpaContract = () => (filters.zpaContract = !filters.zpaContract);
  const clearGlobalFilter = () => (keywordFilters.value["global"].value = "");
  const clearFilters = () => {
    // filters.zpaContract = false;
    clearGlobalFilter();
  };

  // Search term
  const setSearchTerm = (term: string) => (searchTerm.value = term);
  const clearSearchTerm = () => setSearchTerm("");

  // Results
  const setPharmacies = (results: PharmacyInfoModel[]) =>
    (pharmacies.value = results);
  const clearPharmacies = () => setPharmacies([]);

  const getPharmacyById = (id: number): PharmacyInfoModel | undefined =>
    pharmacies.value.find((p: PharmacyInfoModel) => p.systemId === id);

  // Search pharmacies by search term and filterse
  const runSearch = async (searchParams: PharmacySearchReq) => {
    try {
      loading.value = true;
      const { data } = await DataService.searchPharmacies(searchParams);

      if (data.pharmacyInfos) {
        setPharmacies(data.pharmacyInfos);
        console.log(`pharmacies`, data.pharmacyInfos);
      }
    } catch (err: any) {
      const errorMsg = err?.response?.data.resultMessage || err.message;
      console.error(errorMsg);
      clearPharmacies();
      console.log(`error pharma`, pharmacies.value);
    } finally {
      loading.value = false;
    }
  };

  // Clear keyword filter after every new search
  watch(
    () => pharmacies.value,
    () => clearGlobalFilter()
  );

  return {
    filters,
    keywordFilters,
    ...toRefs(filters),
    clearFilters,
    clearGlobalFilter,
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
    pharmacies,
    setPharmacies,
    clearPharmacies,
    getPharmacyById,
    runSearch,
    loading,
  };
};
