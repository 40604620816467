export enum DashboardBtnType {
  Primary,
  Secondary,
  Tertiary,
}

export interface DashboardButtonOptions {
  type: DashboardBtnType;
  routeName: string;
  hyperlink?: boolean;
  href?: string;
  title: string;
  description?: string;
  icon?: string;
  noUppercase?: boolean;
  customIcon?: boolean;
}
