import { createApp } from "vue";
import PrimeVue from "primevue/config";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store/index";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import { FontAwesomeIcon } from "./icons";
import Button from "primevue/button";
import BaseButton from "./components/UI/BaseButton.vue";
import BaseIcon from "./components/UI/BaseIcon.vue";
import BaseBreadcrumb from "./components/UI/BaseBreadcrumb.vue";
import BaseCard from "./components/UI/BaseCard.vue";
import LabeledValue from "./components/UI/LabeledValue.vue";

const app = createApp(App).use(store).use(router).use(PrimeVue);

// Custom components
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Button", Button);
app.component("BaseButton", BaseButton);
app.component("BaseIcon", BaseIcon);
app.component("BaseBreadcrumb", BaseBreadcrumb);
app.component("BaseCard", BaseCard);
app.component("LabeledValue", LabeledValue);

app.mount("#app");
