import { Colorscheme } from "@/models/salesPartner";

export const setColorscheme = (colorscheme: Colorscheme): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      if (!colorscheme) return;

      const root = document.querySelector(":root") as HTMLElement;

      Object.entries(colorscheme).forEach(([key, value]) => {
        if (typeof value === "string") {
          root.style.setProperty(`--${key}`, value);
        }
      });
      resolve("Theme applied");
    } catch (err: any) {
      console.error(err);
      reject(err.message);
    }
  });
