enum LocalStorageKey {
  UserId,
  AccessToken,
  RefreshToken,
}

type localStorageItem = string | null;

export default class LocalStorageService {
  private static _instance: LocalStorageService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static instance(): LocalStorageService {
    if (!this._instance) {
      this._instance = new LocalStorageService();
    }
    return this._instance;
  }

  public setAccessToken(value: string): void {
    localStorage.setItem(LocalStorageKey[LocalStorageKey.AccessToken], value);
  }

  public getAccessToken(): localStorageItem {
    return localStorage.getItem(LocalStorageKey[LocalStorageKey.AccessToken]);
  }
  public removeAccessToken(): void {
    localStorage.removeItem(LocalStorageKey[LocalStorageKey.AccessToken]);
  }

  public hasAccessToken(): boolean {
    return this.getAccessToken() != null;
  }

  public setRefreshToken(value: string): void {
    localStorage.setItem(LocalStorageKey[LocalStorageKey.RefreshToken], value);
  }

  public getRefreshToken(): localStorageItem {
    return localStorage.getItem(LocalStorageKey[LocalStorageKey.RefreshToken]);
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(LocalStorageKey[LocalStorageKey.RefreshToken]);
  }

  public setUser(value: string): void {
    localStorage.setItem(LocalStorageKey[LocalStorageKey.UserId], value);
  }

  public getUser(): localStorageItem {
    return localStorage.getItem(LocalStorageKey[LocalStorageKey.UserId]);
  }

  public removeUser(): void {
    return localStorage.removeItem(LocalStorageKey[LocalStorageKey.UserId]);
  }

  public set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public get(key: string): localStorageItem {
    return localStorage.getItem(key);
  }

  public remove(key: string): localStorageItem {
    return localStorage.getItem(key);
  }
}

export { LocalStorageService, LocalStorageKey };
