import { AxiosInstance } from "axios";
import HttpClient from "./http_client";

export default class IaCrmApi extends HttpClient {
  private static _instance?: IaCrmApi;

  private constructor() {
    // TODO: dev vs prod
    // const env = process.env.NODE_ENV === "prod" ? "qa" : "qa";
    const env = document.location.host.endsWith('noweda.de') ? 'prod' : 'qa';

    super(`https://ia-crm-api-${env}.azurewebsites.net`);
  }

  public static instance(): AxiosInstance {
    if (!this._instance) {
      this._instance = new IaCrmApi();
    }
    return this._instance._axios;
  }
}
