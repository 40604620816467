export enum SalesPartnerId {
  ZPA = 1,
  NOWEDA = 2,
  MEA = 5,
}

export interface Colorscheme {
  "color-primary": string;
  "color-primary-50": string;
  "color-primary-20": string;
  "color-secondary": string;
  "color-action": string;
  "color-hover": string;
  "color-text": string;
  "color-text-secondary": string;
  "color-box-shadow": string;
  "border-color": string;
  "gray-dark": string;
  "page-gradient-from": string;
  "page-gradient-to": string;
  "button-gradient-from": string;
  "button-gradient-to": string;
  "button-gradient-dark-from": string;
  "button-gradient-dark-to": string;
  "font-primary": string;
  "font-display": string;
}
