// Font Awesome
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhoneAlt,
  faEye,
  faEyeSlash,
  faBars,
  faHouseUser,
  faFileContract,
  faClipboardList,
  faQuestionCircle,
  faSignOutAlt,
  faUserCircle,
  faSearch,
  faRedoAlt,
  faMapMarkerAlt,
  faFile,
  faListAlt,
  faClipboardCheck,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Add specific icons
library.add(
  faPhoneAlt as IconDefinition,
  faEye as IconDefinition,
  faEyeSlash as IconDefinition,
  faBars as IconDefinition,
  faHouseUser as IconDefinition,
  faFileContract as IconDefinition,
  faClipboardList as IconDefinition,
  faQuestionCircle as IconDefinition,
  faSignOutAlt as IconDefinition,
  faUserCircle as IconDefinition,
  faSearch as IconDefinition,
  faRedoAlt as IconDefinition,
  faMapMarkerAlt as IconDefinition,
  faFile as IconDefinition,
  faListAlt as IconDefinition,
  faClipboardCheck as IconDefinition,
  faCheckCircle as IconDefinition,
  faTimesCircle as IconDefinition,
);

export { FontAwesomeIcon };
