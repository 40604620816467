import {
  PackageOverview,
  PharmacyDetailsResponse,
  ProductSummary,
} from "@/models/pharmacy";
import {
  PharmacySearchModel,
  PharmacySearchReq,
} from "@/models/pharmacy/pharmacy-search";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  ContractItemDetailsResponse,
  ContractProductResponse,
} from "./../models/order";
import { POSCompletedItemsResponse } from "./../models/pharmacy";
import { Colorscheme } from "./../models/salesPartner";
import Api from "./ia_api_service";

export default class DataService {
  private static api: AxiosInstance = Api.instance();

  static getWhitelabelStyling(
    salesPartnerID: number
  ): Promise<AxiosResponse<Colorscheme>> {
    return this.api.get(`/vertriebsapp/get-css-styles/${salesPartnerID}`);
  }

  static searchPharmacies(
    searchTerms: PharmacySearchReq
  ): Promise<AxiosResponse<PharmacySearchModel>> {
    return this.api.post("/pharmacies/search", searchTerms);
  }

  static getPharmacyDetailsById(
    pharmacyID: number
  ): Promise<AxiosResponse<PharmacyDetailsResponse>> {
    return this.api.get(`/pharmacies/details/${pharmacyID}`);
  }

  static getDetailsPackagesByPharmacyId(
    pharmacyID: number,
    isActivated = true,
    showProductsFromGH = false
  ): Promise<AxiosResponse<PackageOverview[]>> {
    return this.api.get(
      `/orderItems/DetailsPackagesByPharmacyId/${pharmacyID}/${isActivated}`,
      {
        params: {
          showProductsFromGH,
        },
      }
    );
  }

  static getProductSummaryByPharmacyId(
    pharmacyID: number,
    isActivated = true,
    showProductsFromGH = false
  ): Promise<AxiosResponse<ProductSummary[]>> {
    return this.api.get(
      `/orderItems/ProductsSummary/${pharmacyID}/${isActivated}`,
      {
        params: {
          showProductsFromGH,
        },
      }
    );
  }

  static getCompletedOrderItems(
    pharmacyID: number
  ): Promise<AxiosResponse<POSCompletedItemsResponse>> {
    return this.api.get(`/OrderItems/completed/${pharmacyID}`);
  }

  static getDetailsContractsItemsByPersonId(
    isActivated = true
  ): Promise<AxiosResponse<ContractItemDetailsResponse>> {
    return this.api.get(
      `Contracts/DetailsContractsItemsByPersonId/${isActivated}`
    );
  }

  static getProductListByContractId(
    contractID: number
  ): Promise<AxiosResponse<ContractProductResponse>> {
    return this.api.get(`/OrderItems/productlist-by-contractID/${contractID}`);
  }
}
