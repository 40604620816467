import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";

import { Colorscheme, SalesPartnerId } from "@/models/salesPartner";
import { UserModel } from "@/models/user";

export interface State {
  colorscheme: Colorscheme | null;
  salesPartnerId: number | null;
  userInfo: UserModel | null;
  deliveryProductIds: number[];
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    colorscheme: null,
    salesPartnerId: null,
    userInfo: null,
    deliveryProductIds: [4, 15, 16, 18, 19, 35, 36, 37, 40, 44, 45, 46],
  },
  mutations: {
    setColorscheme(state, colorscheme: Colorscheme) {
      if (colorscheme) {
        state.colorscheme = colorscheme;
      }
    },
    removeColorscheme(state) {
      state.colorscheme = null;
    },
    setSalesPartner(state, salesPartnerId: number) {
      state.salesPartnerId = salesPartnerId;
    },
    setUserInfo(state, userInfo: UserModel) {
      state.userInfo = userInfo;
    },
    clearDataOnLogout(state) {
      state.salesPartnerId = null;
      state.userInfo = null;
      state.colorscheme = null;
    },
  },
  actions: {},
  getters: {
    // returns uppercase string (ZPA/MEA/NOWEDA)
    getSalesPartner(state): string {
      return SalesPartnerId[state.salesPartnerId || 1] ?? SalesPartnerId[1];
    },
    getUsername(state): string {
      return state.userInfo?.username ?? "";
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});

export function useStore(): Store<State> {
  return baseUseStore(key);
}
