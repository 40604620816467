import LocalStorageService from "@/services/local_storage_service";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Dashboard from "../views/Dashboard.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: {
      name: "Dashboard",
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "AppLayoutHome",
      label: "Ihr Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/kunden-verwaltung",
    name: "CustomerManagement",
    component: () =>
      import(
        /* webpackChunkName: "customer-management" */ "../views/customer/CustomerManagement.vue"
      ),
    meta: {
      layout: "AppLayoutHome",
      label: "Kunden-Verwaltung",
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/kunden-verwaltung/apotheke/:pharmacyID",
    name: "PharmacyPage",
    component: () =>
      import(
        /* webpackChunkName: "pharmacy-page" */ "../views/customer/PharmacyPage.vue"
      ),
    meta: {
      layout: "AppLayoutHome",
      requiresAuth: true,
    },
  },

  {
    path: "/dashboard/bestellubersicht",
    name: "OrderOverview",
    component: () =>
      import(
        /* webpackChunkName: "order-overview" */ "../views/order/OrderOverview.vue"
      ),
    meta: {
      layout: "AppLayoutHome",
      label: "Bestellübersicht",
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/bestellubersicht/apotheke/:pharmacyID/bestellen/:contractID",
    name: "OrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "order-details-page" */ "../views/order/OrderDetailsPage.vue"
      ),
    meta: {
      layout: "AppLayoutHome",
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/bestellformulare",
    name: "OrderForm",
    component: () =>
      import(
        /* webpackChunkName: "order-form-page" */ "../views/order/OrderFormPage.vue"
      ),
    meta: {
      layout: "AppLayoutHome",
      label: "Bestellformulare",
      requiresAuth: true,
    },
  },
  {
    path: "/hilfe",
    name: "Help",
    component: () => import(/* webpackChunkName: "help" */ "../views/Help.vue"),
    meta: {
      layout: "AppLayoutHome",
      label: "Hilfe",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      layout: "AppLayoutAuth",
      label: "Login",
    },
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "../views/PasswordReset.vue"
      ),
    meta: {
      layout: "AppLayoutAuth",
      label: "",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !LocalStorageService.instance().hasAccessToken()
  ) {
    next({
      name: "Login",
    });
  }
  next();
});
